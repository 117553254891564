<template>
  <Paystack
    v-if="gateway === 'paystack'"
    ref="paystack"
    :amount="amount"
    :email="data.email"
    :firstname="data.first_name"
    :lastname="data.last_name"
    :metadata="paymentMeta"
    :paystackkey="paystackKey"
    :reference="reference"
    :channels="channels"
    :currency="currency"
    :callback="callback"
    :close="close"
    :embed="false"
  />
  <flutterwave-pay-button
    v-else-if="gateway === 'flutterwave'"
    ref="rave"
    :amount="amount"
    :customer="raveMeta"
    :meta="paymentMeta.custom_fields[0]"
    :public-key="raveKey"
    :payment_options="channels"
    :tx_ref="reference"
    :callback="callback"
    :onclose="close"
    :currency="currency"
    :country="location"
  />
</template>

<script type="text/javascript">
import Paystack from 'vue-paystack'

export default {
  name: 'PaymentGateway',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      required: true
    },
    gateway: {
      type: String,
      required: true
    },
    saveCard: {
      type: Boolean,
      required: true
    },
    location: {
      type: String,
      required: true
    }
  },
  components: {
    Paystack
  },
  data () {
    return {
      paystackKey: process.env.VUE_APP_PAYSTACK_KEY,
      raveKey: process.env.VUE_APP_RAVE_KEY
    }
  },
  computed: {
    paymentMeta () {
      return {
        custom_fields: [
          {
            display_name: 'Service e.g (Meal,Laundry,Cleaning,Carebox)',
            service: this.formatTitle(this.data.service),
            payment_type: this.data.payment_type,
            plan: JSON.stringify(this.data.plan),
            save_card: this.saveCard,
            redeemed_discounts: this.data.redeemed_discounts
          }
        ]
      }
    },
    raveMeta () {
      return {
        email: this.data.email,
        name: `${this.data.first_name} ${this.data.last_name ? this.data.last_name : ''}`,
        phone_number: JSON.stringify(this.paymentMeta)
      }
    },
    amount () {
      return this.gateway === 'paystack' ? this.data.amount * 100 : this.data.amount
    },
    currency () {
      return this.location === 'NG' ? 'NGN' : 'KES'
    },
    channels () {
      if (this.gateway === 'paystack') {
        return this.location === 'NG' ? ['card'] : ['card', 'mobile_money']
      } else {
        return 'card'
      }
    },
    reference () {
      const date = new Date()
      const fullDate = `${date.getDate()}${date.getMonth() + 1}${date.getHours()}${date.getMinutes()}`
      return `EDEN-PAYMENT-${fullDate}`
    }
  },
  methods: {
    callback (response) {
      if (response.status === 'success') {
        this.close(true)
      }
    },
    close (successStatus) {
      this.$emit('close', !!successStatus)
    }
  },
  watch: {
    show () {
      if (this.show) {
        this.gateway === 'paystack' ? this.$refs.paystack.$el.click() : this.$refs.rave.$el.click()
      }
    }
  }
}
</script>
<style scoped>
.payButton,
button {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
</style>
