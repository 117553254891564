<template>
  <loader v-if="loading" />
  <div v-else class="eden-page--wrapper">
    <div class="eden-logo">
        <img :src="getImageUrl('eden.svg')" alt="Eden" />
      </div>
    <div class="card">
      <div v-if="isEdenCustomer" class="card-section">
        <h6>{{ data.first_name }} {{  data.last_name  }}</h6>
        <p class="text-primary">{{ data.email }}</p>
      </div>
      <div class="card-section">
        <h1>{{ formatPrice(data.amount) }}</h1>
        <div class="breakdown">
          <p class="trigger" @click="showBreakdown = !showBreakdown">{{ showBreakdown ? 'Hide' : 'Show' }} payment breakdown
            <img :src="getImageUrl('arrow.svg')" alt="Eden" :class="{ invert: showBreakdown }" />
          </p>
          <div v-if="showBreakdown" class="breakdown-table">
            <p><span>Type</span><span>{{ formatPaymentType(data.payment_type) }}</span></p>
            <template v-if="data.plan && Object.keys(data.plan).length">
              <p
                v-for="(value, key, index) in data.plan"
                :key="index">
                <span>{{ formatText(key) }}</span>
                <span>{{ formatPrice(value.amount) }}</span>
              </p>
            </template>
            <template v-else>
              <p>
                <span>Services</span>
                <span>{{ formatTitle(data.service) }}</span>
              </p>
            </template>
            <p class="total"><span>Amount to pay</span><span>{{ formatPrice(data.amount) }}</span></p>
          </div>
        </div>
      </div>
      <form id="payment" :class="{ adjust: showBreakdown }">
        <div v-if="!isEdenCustomer" class="card-section">
          <div class="form-group">
            <div class="form-input" :class="{ 'form-input--error': $v.data.first_name.$error }">
              <label for="first_name">First name</label>
              <input id="first_name" type="text" v-model.trim="$v.data.first_name.$model" />
              <span v-if="$v.data.first_name.$error">First name is required</span>
            </div>
            <div class="form-input" :class="{ 'form-input--error': $v.data.last_name.$error }">
              <label for="last_name">Last name</label>
              <input id="last_name" type="text" v-model.trim="$v.data.last_name.$model" />
              <span v-if="$v.data.last_name.$error">Last name is required</span>
            </div>
          </div>
          <div class="form-group">
            <div class="form-input" :class="{ 'form-input--error': $v.data.email.$error }">
              <label for="email">Email</label>
              <input id="email" type="text" v-model.trim="$v.data.email.$model" />
              <span v-if="$v.data.email.$error">Email is not valid</span>
            </div>
          </div>
        </div>
        <template v-if="location === 'NG'">
          <div v-if="!definedGateway" class="card-section">
            <div class="gateways-label">
              <p>Choose your preferred payment provider</p>
            </div>
            <div class="gateways">
              <div class="radio">
                <input id="flutterwave" type="radio" name="gateway" v-model="gateway" value="flutterwave" />
                <label for="flutterwave">
                  <img :src="getImageUrl('flutterwave.png')" alt="Flutterwave" />
                </label>
              </div>
              <div class="radio">
                <input id="paystack" type="radio" v-model="gateway" value="paystack" />
                <label for="paystack">
                  <img :src="getImageUrl('paystack.png')" alt="Paystack" />
                </label>
              </div>
            </div>
          </div>
        </template>
        <div class="card-section">
          <div class="alert info">
            <p>
              We use third party payment gateways to process payment.<br />
              <span v-if="location === 'NG'">These payment providers are licensed by CBN.</span></p>
          </div>
        </div>
        <div class="card-section">
          <div class="save-card">
            <input type="checkbox" v-model="saveCard">
            <span class="checkmark"></span>
            <span class="label">Save my card details so Eden can put my services on auto-pilot.</span>
          </div>
        </div>
        <button :class="['btn', 'btn-primary', { disabled: disablePaymentButton }]" :disabled="disablePaymentButton" @click.prevent="pay">
          <span>Pay {{ formatPrice(data.amount) }}</span>
        </button>
      </form>
    </div>
    <payment-gateway :show="openPaymentMethod" :data="data" :gateway="gateway" :save-card="saveCard" :location="location" @close="closePaymentMethod" />
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import PaymentGateway from '@/components/PaymentGateway'

export default {
  name: 'PaymentHome',
  components: { Loader, PaymentGateway },
  mixins: [validationMixin],
  validations: {
    data: {
      first_name: {
        required
      },
      last_name: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  data () {
    return {
      loading: false,
      location: 'NG',
      data: {
        first_name: '',
        last_name: '',
        email: ''
      },
      showBreakdown: false,
      saveCard: true,
      gateway: '',
      definedGateway: '',
      isEdenCustomer: true,
      openPaymentMethod: false
    }
  },
  computed: {
    disablePaymentButton () {
      if (this.isEdenCustomer && this.gateway) {
        return false
      }
      return !!(this.$v.$invalid || this.gateway === '' || this.data.amount === 0)
    }
  },
  created () {
    this.loading = true
    this.getData()
  },
  methods: {
    async getData () {
      const URL = `${process.env.VUE_APP_API_BASE_URL}/${this.$route.params.slug}`
      const dataResponse = await fetch(URL, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (dataResponse.status === 200) {
        const { data } = await dataResponse.json()
        this.data = data
        this.location = this.data.domicile_location
        if (this.data.payment_type.includes('renewal')) {
          this.definedGateway = 'paystack'
          this.gateway = 'paystack'
        }

        if (this.location === 'KE') {
          this.gateway = 'paystack'
        }

        this.isEdenCustomer = !!this.data.email
        this.loading = false
      } else if (dataResponse.status === 404) {
        await this.$router.push({ name: 'error' })
      }
    },
    pay () {
      if (this.gateway) {
        this.openPaymentMethod = true
      }
      return false
    },
    closePaymentMethod (status) {
      this.openPaymentMethod = false
      this.gateway = this.location === 'NG' ? '' : 'paystack'
      if (status) {
        this.$router.push({ name: 'payment-successful', params: { service: this.data.service } })
      }
    },
    formatPrice (amount) {
      const symbol = this.location === 'NG' ? '₦' : 'KES'
      return `${symbol}${Number(amount || 0).toLocaleString()}.00`
    }
  }
}
</script>
<style scoped lang="scss">
.breakdown {
  width: 100%;
  margin-top: 15px;
  .trigger {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
    color: #6B594D;

    img {
      margin-left: 5px;
      position: relative;
      transform: rotateX(0deg);
      transition: transform 0.25s ease-out;
      &.invert {
        transform: rotateX(180deg);
        transition: transform 0.25s ease-in;
      }
    }
  }

  &-table {
    -webkit-animation: fadein 0.3s linear forwards;
    animation: fadein 0.3s linear forwards;
    width: 100%;
    border: 1px dashed #FFB813;
    background: #FFFCF5;
    border-radius: 8px;
    padding: 16px;

    p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;

      &:first-child {
        margin-top: 0;
      }
      span {
        font-size: 14px;
        line-height: 1.6;

        &:first-child {
          color: #493B31;
        }

        &:last-child {
          color: #271D17;
        }
      }
    }

    .total {
      font-weight: 500;
      border-top: 1px solid #FBDFC5;
      padding-top: 10px;
      margin-bottom: 0;

      span {
        color: #271D17 !important;
      }
    }
  }
}

form {
  position: relative;
  top: 0;
  transition: top 0.3s ease-out;

  &.adjust {
    top: 10px;
    transition: top 0.3s ease-in;
  }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
</style>
